
























































import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

import ChatSection from '@/components/Assistant/ChatSection.vue';

@Component({
  components: {
    ChatSection
  },
  model: {
      prop: "model",
      event: "openedChanged"
  }
})
export default class ChatAside extends Vue {
    // Fields

    // Properties
    @Prop({required: false})
    public instance?: string;

    @Prop({ required: false, default: true, type: Boolean })
    public model!: boolean;

    @Prop({ required: false, default: 'fixed', type: String })
    public position!: 'fixed' | 'sticky';

    @Prop({ required: false, default: 0, type: Number })
    public elevation?: number;

    @Prop({ required: false})
    public maxWidth?: number;
    
    // Getter:
    public get opened(): boolean {
        return this.model;
    }
    public set opened(value: boolean) {
        this.fireOpenedChanged(value);
    }
    
    // Watchers & Event Handlers:

    // Component Lifecycle

    // Methods
    public close(): void {
      this.opened = false;
    }

    public open(): void {
      this.opened = true;
    }

    public fireOpenedChanged(value: boolean): void {
        this.$emit('openedChanged', value);
    }

}
