



























import Vue from 'vue'
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { postUserMessage } from './ChatSection.vue';

@Component({})
export default class ExplainButton extends Vue {
    // Fields

    // Properties
    @Prop({required: true})
    public message!: string;

    @Prop({required: false})
    public title?: string;

    @Prop({required: false})
    public chatInstance?: string;

    @Prop({required: false})
    public instruction?: string;

    @Prop({required: false})
    public plain?: boolean;

    @Prop({required: false})
    public icon?: boolean;

    @Prop({required: false})
    public fab?: boolean;

    @Prop({required: false})
    public color?: string;
    // Getter:
    
    // Watchers & Event Handlers:

    // Component Lifecycle

    // Methods
    public postAssistantMessage() {
      postUserMessage(this.message, this.instruction, this.chatInstance);
      this.firePosted();
    }

    public firePosted(): void {
      this.$emit("posted");
    }

}
