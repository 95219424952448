






































import Vue from 'vue'
import { Component, Emit, Prop, Watch } from 'vue-property-decorator';
import { postUserMessage } from './ChatSection.vue';

@Component({})
export default class ExplainConflictButton extends Vue {
    // Fields
    public showExplanation = false;

    // Properties
    @Prop({required: true})
    public message!: string;

    @Prop({required: false})
    public title?: string;

    @Prop({required: false})
    public instruction?: string;

    @Prop({required: false})
    public plain?: boolean;

    @Prop({required: false})
    public icon?: boolean;

    @Prop({required: false})
    public fab?: boolean;

    @Prop({required: false})
    public color?: string;
    // Getter:
    
    // Watchers & Event Handlers:

    // Component Lifecycle

    // Methods
    public postAssistantMessage() {      
      this.showExplanation = true;
    }
}
