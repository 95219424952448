




























import Vue from 'vue'
import { Component, Prop } from 'vue-property-decorator';

@Component({})
export default class DomainChip extends Vue {
    // Fields

    // Component Properties
    @Prop({required: true})
    public domain!: string;

    @Prop({ required: true })
    public available!: boolean;

    @Prop({ required: false, default: false, type: Boolean })
    public premium!: boolean;

    // Getter & Setter
    public get color(): string {
        return this.available ? 'green darken-3' : 'red darken-3';
    }

    // Methods
}
